$(document).ready(function() {

    "use strict";

    // Options
    var submenu_animation_speed = 0,
        submenu_opacity_animation = false, // set to "false" to remove opacity animation
        page_boxed = false,
        page_sidebar_fixed = false,
        page_sidebar_collapsed = false,
        page_header_fixed = false;
    $.support.transition = false;

    // Elements
    var body = $('body'),
        page_header = $('.page-header'),
        page_sidebar = $('.page-sidebar'),
        page_content = $('.page-content');

    // Boxed Page
    var boxed_page = function() {
        if(page_boxed === true) {
            $('.page-container').addClass('container');
        };
    };


    // Fixed Header
    var fixed_header = function() {
        if(page_header_fixed === true) {
            $('body').addClass('page-header-fixed');
        };
    };

    // Sidebar
    var page_sidebar_init = function() {

        // Slimscroll
        $('.page-sidebar-inner').slimScroll({
            height: '100%'
        }).mouseover();

        // Fixed Sidebar
        var fixed_sidebar = function() {
            if((body.hasClass('page-sidebar-fixed'))&&(page_sidebar_fixed === false)) {
                page_sidebar_fixed = true;
            };

            if(page_sidebar_fixed === true) {
                body.addClass('page-sidebar-fixed');
                $('#fixed-sidebar-toggle-button').removeClass('icon-radio_button_unchecked');
                $('#fixed-sidebar-toggle-button').addClass('icon-radio_button_checked');
            };

            var fixed_sidebar_toggle = function() {
                body.toggleClass('page-sidebar-fixed');
                if(body.hasClass('page-sidebar-fixed')) {
                    page_sidebar_fixed = true;
                } else {
                    page_sidebar_fixed = false;
                }
            };

            $('#fixed-sidebar-toggle-button').on('click', function() {
                fixed_sidebar_toggle();
                $(this).toggleClass('icon-radio_button_unchecked');
                $(this).toggleClass('icon-radio_button_checked');
                return false;
            });
        };


        // Collapsed Sidebar
        var collapsed_sidebar = function() {
            if(page_sidebar_collapsed === true) {
                body.addClass('page-sidebar-collapsed');
            };

            var collapsed_sidebar_toggle = function() {
                body.toggleClass('page-sidebar-collapsed');
                if(body.hasClass('page-sidebar-collapsed')) {
                    page_sidebar_collapsed = true;
                } else {
                    page_sidebar_collapsed = false;
                };
                $('.page-sidebar-collapsed .page-sidebar .accordion-menu').on({
                    mouseenter: function(){
                        $('.page-sidebar').addClass('fixed-sidebar-scroll')
                    },
                    mouseleave: function(){
                        $('.page-sidebar').removeClass('fixed-sidebar-scroll')
                    }
                }, 'li');
            };

                $('.page-sidebar-collapsed .page-sidebar .accordion-menu').on({
                    mouseenter: function(){
                        $('.page-sidebar').addClass('fixed-sidebar-scroll')
                    },
                    mouseleave: function(){
                        $('.page-sidebar').removeClass('fixed-sidebar-scroll')
                    }
                }, 'li');
            $('#collapsed-sidebar-toggle-button').on('click', function() {
                collapsed_sidebar_toggle();
                return false;
            });

        };

        var small_screen_sidebar = function(){
            if(($(window).width() < 768)&&($('#fixed-sidebar-toggle-button').hasClass('icon-radio_button_unchecked'))){
                $('#fixed-sidebar-toggle-button').click();
            }
            $(window).on('resize', function() {
                if(($(window).width() < 768)&&($('#fixed-sidebar-toggle-button').hasClass('icon-radio_button_unchecked'))){
                    $('#fixed-sidebar-toggle-button').click();
                }
            });
            $('#sidebar-toggle-button').on('click', function() {
                body.toggleClass('page-sidebar-visible');
                return false;
            });
            $('#sidebar-toggle-button-close').on('click', function() {
                body.toggleClass('page-sidebar-visible');
                return false;
            });
        };

        fixed_sidebar();
        collapsed_sidebar();
        small_screen_sidebar();
    };


    // Accordion menu
    var accordion_menu = function() {

        var select_sub_menus = $('.page-sidebar li:not(.open) .sub-menu'),
            active_page_sub_menu_link = $('.page-sidebar li.active-page > a');

        // Hide all sub-menus
        select_sub_menus.hide();

        // Accordion
        $('.accordion-menu').on('click', 'a', function() {
            var sub_menu = $(this).next('.sub-menu'),
                parent_list_el = $(this).parent('li'),
                active_list_element = $('.accordion-menu > li.open'),
                show_sub_menu = function() {
                    sub_menu.slideDown(submenu_animation_speed);
                    parent_list_el.addClass('open');
                    if(submenu_opacity_animation === true) {
                        $('.open .sub-menu li').each(function(i){
                            var t = $(this);
                            setTimeout(function(){ t.addClass('animation'); }, (i+1) * 15);
                        });
                    };
                },
                hide_sub_menu = function() {
                    if(submenu_opacity_animation === true) {
                        $('.open .sub-menu li').each(function(i){
                            var t = $(this);
                            setTimeout(function(){ t.removeClass('animation'); }, (i+1) * 5);
                        });
                    };
                    sub_menu.slideUp(submenu_animation_speed);
                    parent_list_el.removeClass('open');
                },
                hide_active_menu = function() {
                    $('.accordion-menu > li.open > .sub-menu').slideUp(submenu_animation_speed);
                    active_list_element.removeClass('open');
                };

            if((sub_menu.length)&&(!body.hasClass('page-sidebar-collapsed'))) {

                if(!parent_list_el.hasClass('open')) {
                    if(active_list_element.length) {
                        hide_active_menu();
                    };
                    show_sub_menu();
                } else {
                    //hide_sub_menu();
                };

                return false;

            };
            if((sub_menu.length)&&(body.hasClass('page-sidebar-collapsed'))){
                return false;
            };
        });

        if($('.active-page > .sub-menu').length) {
            active_page_sub_menu_link.click();
        };
    };


    // Fulscreen Function
    function toggleFullScreen() {
        if (!document.fullscreenElement &&    // alternative standard method
            !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement ) {  // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.msRequestFullscreen) {
                document.documentElement.msRequestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    };

    // Navbar
    var navbar_init = function(){

        $('#toggle-fullscreen').on('click', function(){
            toggleFullScreen();
            return false;

        });

        $('#search-button').on('click', function(){
            body.toggleClass('search-open')
            if(body.hasClass('search-open')) {
                $('.search-form input').focus();
            }
        });

        $('#close-search').on('click', function(){
            body.toggleClass('search-open')
        });

    };

    // Right Sidebar
    var right_sidebar = function(){
        $('.right-sidebar-toggle').on('click', function(){
            var sidebarId = $(this).data("sidebar-id");
            $('#' + sidebarId).toggleClass('visible');
        });

        var write_message = function(){
            $(".chat-write form input").on('keypress', function (e) {
                if ((e.which === 13)&&(!$(this).val().length === 0)) {
                    if($('.right-sidebar-chat .chat-bubbles .chat-bubble:last-child').hasClass('me')) {

                    $('<span class="chat-bubble-text">' + $(this).val() + '</span>').insertAfter(".right-sidebar-chat .chat-bubbles .chat-bubble:last-child span:last-child");
                    } else {
                        $('<div class="chat-bubble me"><div class="chat-bubble-text-container"><span class="chat-bubble-text">' + $(this).val() + '</span></div></div>').insertAfter(".right-sidebar-chat .chat-bubbles .chat-bubble:last-child");
                    };
                    $(this).val('');
                } else if(e.which === 13) {
                    return;
                }
                var scrollTo_int = $('.right-sidebar-chat').prop('scrollHeight') + 'px';
                $('.right-sidebar-chat').slimscroll({
                    allowPageScroll: true,
                    scrollTo : scrollTo_int
                });
            });
        };
        write_message();
    };

    // Plugins
    var plugins_init = function(){
        // Slimscroll
        $('.slimscroll').slimScroll();
        $('.slimscrollNotification').slimScroll({
            height: '350px'
        });

        var elems = Array.prototype.slice.call(document.querySelectorAll('.js-switch'));
        elems.forEach(function(html) {
            var switchery = new Switchery(html, {size: 'small', color: '#637282'});
        });

        // Vehicle model change.
        $('body').on("change", ".vehicleModelSelect", function () {
            var type = $(this).select2('data')[0].type;
            if (type == 1) {
                $('.carrierOnly').removeClass("hidden").find(".required input").prop("required", true);
                $('.frequencySelect').empty().select2({
                    data: [
                        {
                            text: "Quilômetros",
                            id: "vdo"
                        },
                        {
                            text: "Dias",
                            id: "days"
                        }
                    ]
                });
            }
            else if (type == 2) {
                $('.carrierOnly').addClass("hidden").find(".required input").prop("required", false);
                $('.frequencySelect').empty().select2({
                    data: [{
                        text: "Dias",
                        id: "days"
                    }]
                });
            }
        });

        $('body').on("change", ".selectVehicle", function () {
            var vdo = $(this).select2('data')[0].vdo;
            var vdoTarget = $('.vdoTarget');
            if (vdoTarget.val() === "0" || vdoTarget.val() === "") {
                vdoTarget.val(vdo).trigger('mask.maskMoney');
            }
        });

        $('body').on("submit", "#copyPlanForm", function (e) {
             e.preventDefault();
            var services = $(this).find('select[name="vehicle"]').select2('data')[0].services;
            var arr = services.split(',');
            $('.cycleInput').val("").trigger('change');
            $.each( arr, function(index, cycle) {
                cycle = cycle.split('|');
                if (cycle[1] !== undefined && cycle[2] !== undefined) {
                    if (cycle[1].length !== 0 && cycle[2].length !== 0) {
                         var id = cycle[0];
                         $('input[name="cycle_qty_' + id + '"]').val(cycle[1]).trigger('mask.maskMoney');
                         $('select[name="cycle_type_' + id + '"]').val(cycle[2]).trigger('change');
                    }
               }
            });
            $('#copyPlan').modal('hide');
        });

        // AJAX for SELECT2
        function callSelect2Plugin() {
            $("select").select2();
            $.each($('.select2'), function() {
                var select2route = $( this ).attr("data-select");
                if(select2route){
                    ajaxSelect2(this, select2route);
                }
            });
        } callSelect2Plugin();

        function ajaxSelect2(element, url) {
            var page;
            $(element).select2({
                    placeholder: "Opcional",
                    allowClear: true,
                    ajax: {
                        url: url,
                        dataType: 'json',
                        method: "GET",
                        data: function (params) {
                            var searchValue = new Object();
                            searchValue['value'] = params.term;
                                params.page = params.page || 0;
                            page = params.page * 10;
                            return {start: page, search: searchValue};
                        },
                        processResults: function (data, params) {
                            params.page = params.page || 0;
                            page = params.page * 10;
                            if (page < data.recordsTotal) {
                                return {
                                    results: data.data,
                                    pagination: {
                                        more: (page) < data.recordsTotal
                                    }
                                };
                            }
                        },
                        cache: true
                    },
                    escapeMarkup: function (markup) {
                        return markup;
                    },
                    templateResult: formatSelect2,
                    templateSelection: formatSelect2Selecions
                }
            );
            // Assign predefined option.
            var predefinedID = $(element).data("id");
            var predefinedText = $(element).data("text");
            if (predefinedID) {
                if (predefinedID.length !== 0) {
                    var newOption = new Option(predefinedText, predefinedID, false, false);
                    $(element).append(newOption).trigger('change');
                }
            }
        }

        $(document).on('click', '.select2:not(.select2-container--focus)', function (e) {
            if (e.originalEvent) {
                $(this).siblings('select').select2('open');
            }
        });

        function formatSelect2 (row) {
            if (row.loading) return row.text;

            var markup = '<div class="clearfix">' +
                '<div class="col-sm-6">' + row.name + '</div>' +
                '</div>';
            return markup;
        }

        function formatSelect2Selecions (row) {
            if (row.name === undefined) {
                return row.text;
            } else {
                return row.name;
            }
        }

        var document_qtd = $('[data-container="document"]').length;
        $('body').on("click", "[data-toggle='document']", function(e) {
            e.preventDefault();
            var document = $("#document");
            var error = $(".document_error");
            var container = $("[data-container='document']");

            if (document.val() === "") {
                error.html("Selecione a foto").removeClass("hidden");
            } else {
                var id = document_qtd++;
                var html = "<div class='col-md-6 item' id='document_"+id+"'>";
                var base64 = $('.documentForm .fileinput-preview.fileinput-exists.thumbnail img').attr("src");

                html += "<input type='hidden' name='document[id][]' value='' />";
                html += "<input type='hidden' name='document[url][]' value='' />";
                html += "<input type='hidden' name='document[base64][]' value='"+base64+"' />";

                html += "<div class='panel'>";
                html += "<div class='panel-content'>";
                html += "<img src='"+base64+"' class='img-responsive' />";
                html += "</div>";
                html += "<div class='panel-footer text-right'>";
                html += "<a href='' class='btn btn-danger remove' data-target='"+id+"'><i class='fa fa-trash p-r-0'></i></a>";
                html += "</div>";
                html += "</div>";
                html += "</div>";
                container.prepend(html);
                error.addClass("hidden");
                document.val("");
                $('.documentForm .btn.btn-danger.fileinput-exists').click();
                $(".item_empty").addClass("hidden");
            }
        });
        $("[data-container='document']").on("click", ".remove", function(e) {
            e.preventDefault();
            var id = $(this).data("target");
            $("#document_"+id).html("").remove();
            var container = $("[data-container='document']");
            if (container.find(".item").length <= 0) {
                container.find(".item_empty").removeClass("hidden");
            }
        });

        // Update the cached items for report filter for the user.
        $('body').on("change", "#reportForm select[name='vehicle']", function (e) {
            if ($(this).val() != null) {
                 $("#reportForm select[name='fleet']").val('').trigger('change');
              }
        });
        $('body').on("change", "#reportForm select[name='fleet']", function (e) {
            if ($(this).val() != null) {
                 $("#reportForm select[name='vehicle']").val('').trigger('change');
            }
        });
        $('body').on("change", "#reportForm input, #reportForm select", function (e) {
            var sendData = $('#reportForm').serializeArray();
            $.post("/cache", sendData);
        });

        function numberToReal(number, symbol) {
            number = number.toFixed(2).split('.');
            number[0] = number[0].split(/(?=(?:...)*$)/).join('.');
            if (symbol) {
                return symbol + number.join(',');
            } else {
                return number.join(',');
            }
        }

        function realToNumber(value) {
            if (value === ""){
                value =  0;
            }else{
                value = value.toString();
                value = value.split(".").join("");
                value = value.replace(",", ".");
                value = parseFloat(value);
            }
            return value;
        }

        function addError(element, message) {
            element.html('<div class="alert alert-danger">\n' +
                '          <button type="button" class="close" data-dismiss="alert" aria-label="Fechar"><span aria-hidden="true">&times;</span></button>\n' +
                '                    ' + message +
                '</div>');
        }

        function addSuccess(element, message) {
            element.html('<div class="alert alert-success">\n' +
                '          <button type="button" class="close" data-dismiss="alert" aria-label="Fechar"><span aria-hidden="true">&times;</span></button>\n' +
                '                    ' + message +
                '</div>');
        }

    };

    page_sidebar_init();
    boxed_page();
    accordion_menu();
    navbar_init();
    right_sidebar();
    plugins_init();

    // Add masks to inputs
    $.mask.definitions['d'] = '[0-9.]';
    $(".phones").mask("(99) 99999999?9");
    $(".cpf").mask("999.999.999-99");
    $(".cnh").mask("99999999999");
    $(".cnpj").mask("99.999.999/9999-99");
    $(".cep").mask("99999-999");
    $(".year").mask("9999");
    $(".plate").mask("aaa ****");
    $(".renavam").mask("999999999?99");
    $(".chassi").mask("**aaa99a9*a999999");
    $('.money-mask').maskMoney({showSymbol:false,thousands:'.',decimal:',',precision:2});
    $('.money-mask').each(function () {
        if ($(this).val() === "0,00") {
            $(this).val('').trigger('change');
        }
    });
    $('.float-mask').maskMoney({showSymbol:false,thousands:'.',decimal:'.',precision:0});
    $('.float-mask').each(function () {
        $(this).trigger('mask.maskMoney');
        if ($(this).val() === "0") {
            $(this).val('');
        }
    });
    $('.amount-mask').mask('9?dddddddddd', ' ', {reverse: true});
    $('.amount-mask').each(function () {
        if ($(this).val() === "0") {
            $(this).val('').trigger('change');
        }
    });
    $(".numerical").mask("9?9999999999999999");
    $('.numerical').each(function () {
        if ($(this).val() === "0") {
            $(this).val('').trigger('change');
        }
    });

    $('form[data-toggle="validateForm"]').validator({
        custom: {
            cpf: function($el) {
                if ($el.val() === "___.___.___-__" && $el.prop("required") === false){
                    return false
                }
                if ($el.val().length !== 0) {
                    var valid = validate_cpf($el.val());
                    if (valid == false) {
                        return true
                    }
                }
            },
            cnpj: function($el) {
                if ($el.val() === "__.___.___/____-__" && $el.prop("required") === false){
                    return false
                }
                if ($el.val().length !== 0) {
                    var valid = validate_cnpj($el.val());
                    if (valid == false) {
                        return true
                    }
                }
            }
        },
        errors: {
            cpf: "CPF inválido",
            cnpj: "CNPJ inválido"
        }
    });

    // Search a address by the zip code number.
    $("#cep, .cep").blur(function() {
        if($.trim($("#zip_code").val()) != ""){
            $.post("https://viacep.com.br/ws/"+$("#zip_code").val()+"/json/", function(data){
                if(data.cep){
                    $("#street").val(unescape(data.logradouro));
                    $("#district").val(unescape(data.bairro));
                    $("#city").val(unescape(data.localidade));
                    $("#state").val(unescape(data.uf));
                    $("#country").val("Brasil");
                }
            });
        }
    });

    $('[data-toggle="tooltip"]').tooltip({
        container : 'body'
    });

    /* Color picker */
    $(".colorpicker").spectrum({
        preferredFormat: "hex3",
        flat: false,
        showSelectionPalette: false,
        showInput: false
    });
    $(".colorpicker").show();

    /* Date & Time picker */
    $('.datetimepicker').each(function () {
        $(this).datetimepicker({
            format: 'DD/MM/YYYY HH:mm',
            locale: 'pt-br',
            useCurrent: false,
            showClose: true,
            icons: {
               close: 'fa fa-check c-green'
            },
            tooltips: {
                    today: '',
                    clear: '',
                    close: '',
                    selectMonth: '',
                    prevMonth: '',
                    nextMonth: '',
                    selectYear: '',
                    prevYear: '',
                    nextYear: '',
                    selectDecade: '',
                    prevDecade: '',
                    nextDecade: '',
                    prevCentury: '',
                    nextCentury: '',
                    incrementHour: '',
                    pickHour: '',
                    decrementHour:'',
                    incrementMinute: '',
                    pickMinute: '',
                    decrementMinute:'',
                    incrementSecond: '',
                    pickSecond: '',
                    decrementSecond:'',
            }
        });
        if ($(this).val() == "01/01/0001 00:00") {
            $(this).val('');
        }
    });

    /* Date picker */
    function getDatePicker() {
        $('.datepicker').each(function () {
            $(this).datetimepicker({
                format: 'DD/MM/YYYY',
                locale: 'pt-br',
                useCurrent: false,
                tooltips: {
                        today: '',
                        clear: '',
                        close: '',
                        selectMonth: '',
                        prevMonth: '',
                        nextMonth: '',
                        selectYear: '',
                        prevYear: '',
                        nextYear: '',
                        selectDecade: '',
                        prevDecade: '',
                        nextDecade: '',
                        prevCentury: '',
                        nextCentury: '',
                        incrementHour: '',
                        pickHour: '',
                        decrementHour:'',
                        incrementMinute: '',
                        pickMinute: '',
                        decrementMinute:'',
                        incrementSecond: '',
                        pickSecond: '',
                        decrementSecond:'',
                }
            });
        });

        $('.timepicker').each(function () {
            $(this).datetimepicker({
                format: 'HH:mm',
                locale: 'pt-br',
                useCurrent: false,
                tooltips: {
                        today: '',
                        clear: '',
                        close: '',
                        selectMonth: '',
                        prevMonth: '',
                        nextMonth: '',
                        selectYear: '',
                        prevYear: '',
                        nextYear: '',
                        selectDecade: '',
                        prevDecade: '',
                        nextDecade: '',
                        prevCentury: '',
                        nextCentury: '',
                        incrementHour: '',
                        pickHour: '',
                        decrementHour:'',
                        incrementMinute: '',
                        pickMinute: '',
                        decrementMinute:'',
                        incrementSecond: '',
                        pickSecond: '',
                        decrementSecond:'',
                }
            });
        });

        $('.yearpicker').each(function () {
            $(this).datetimepicker({
                format: 'YYYY',
                locale: 'pt-br',
                useCurrent: false,
                tooltips: {
                        today: '',
                        clear: '',
                        close: '',
                        selectMonth: '',
                        prevMonth: '',
                        nextMonth: '',
                        selectYear: '',
                        prevYear: '',
                        nextYear: '',
                        selectDecade: '',
                        prevDecade: '',
                        nextDecade: '',
                        prevCentury: '',
                        nextCentury: '',
                        incrementHour: '',
                        pickHour: '',
                        decrementHour:'',
                        incrementMinute: '',
                        pickMinute: '',
                        decrementMinute:'',
                        incrementSecond: '',
                        pickSecond: '',
                        decrementSecond:'',
                }
            });
        });

        $(".datestart").on("dp.change", function (e) {
            $('.dateend').data("DateTimePicker").minDate(e.date);
        });
        $(".dateend").on("dp.change", function (e) {
            $('.datestart').data("DateTimePicker").maxDate(e.date);
        });

        // Listen for click on toggle checkbox
        $('.select-all').click(function(event) {
            var checkClass = $(this).val();
            if(this.checked || this.indeterminate) {
                // Iterate each checkbox
                $('[data-select="'+checkClass+'"]:not(.select-all)').each(function() {
                    this.checked = true;
                });
            } else {
                $('[data-select="'+checkClass+'"]:not(.select-all)').each(function() {
                    this.checked = false;
                });
            }
        });
        $('[data-select]').change(function(event) {
            var checkClass = $(this).data("select");
            $('.select-all[name="'+checkClass+'"]').prop("indeterminate", true);
        });

    }
    getDatePicker();

    // Custom scrolls.
    var thisHeight, windowHeight;
    $('.withScroll').each(function() {
        $(this).mCustomScrollbar("destroy");
        var scroll_height = $(this).data('height') ? $(this).data('height') : 'auto';
        var data_padding = $(this).data('padding') ? $(this).data('padding') : 0;
        if ($(this).data('height') == 'window') {
            thisHeight = $(this).height();
            windowHeight = $(window).height() - data_padding - 50;
            if (thisHeight < windowHeight) scroll_height = thisHeight;
            else scroll_height = windowHeight;
        }
        $(this).mCustomScrollbar({
            scrollButtons: {
                enable: false
            },
            autoHideScrollbar: $(this).hasClass('show-scroll') ? false : true,
            scrollInertia: 150,
            theme: "light",
            set_height: scroll_height,
            advanced: {
                updateOnContentResize: true
            }
        });
    });

});

function generatePeriod(dateStartStr, dateEndStr, baseUrl) {
    var description;
    dateStart = dateStartStr.substring(0, 10);
    var dateStart = moment(dateStartStr);
    dateEnd = dateEndStr.substring(0, 10);
    var dateEnd = moment(dateEndStr);

    $('.period').html('<div class="col-xs-12 col-md-12 col-lg-4 m-b-10 p-l-0 dataTables_filter"><div class="input-group"><span class="input-group-addon"><span class="fa fa-calendar"></span></span><input class="form-control periodListSelector m-l-0" placeholder="Selecionar período" /></div></div>');

	var periodListSelector = $('.periodListSelector');
	if (dateStartStr !== "0001-01-01" && dateEndStr !== "0001-01-01") {
		periodListSelector.val(dateStart.format("DD/MM/YYYY") + " - " + dateEnd.format("DD/MM/YYYY"))
	}
     periodListSelector.daterangepicker({
		autoUpdateInput: false,
		autoApply: true,
		opens: 'left',
	   	locale: {
			format: 'DD/MM/YYYY',
			monthNames: [
	            "Janeiro",
	            "Fevereiro",
	            "Março",
	            "Abril",
	            "Maio",
	            "Junho",
	            "Julho",
	            "Agosto",
	            "Setembro",
	            "Outubro",
	            "Novembro",
	            "Dezembro"
	        	]
		}
     });
     periodListSelector.on('apply.daterangepicker', function(ev, picker) {
     	$(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
		window.location = baseUrl + "&date_start=" + picker.startDate.format("YYYY-MM-DD") + "&date_end=" + picker.endDate.format("YYYY-MM-DD");
	});
     $('body').on("change", ".periodListSelector", function () {
          if ($(this).val() === "") {
               window.location = baseUrl
          }
     });
}

function validate_cnpj(val) {

    if (val.match(/^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/) != null) {
        var val1 = val.substring(0, 2);
        var val2 = val.substring(3, 6);
        var val3 = val.substring(7, 10);
        var val4 = val.substring(11, 15);
        var val5 = val.substring(16, 18);

        var i;
        var number;
        var result = true;

        number = (val1 + val2 + val3 + val4 + val5);

        s = number;

        c = s.substr(0, 12);
        var dv = s.substr(12, 2);
        var d1 = 0;

        for (i = 0; i < 12; i++)
            d1 += c.charAt(11 - i) * (2 + (i % 8));

        if (d1 == 0)
            result = false;

        d1 = 11 - (d1 % 11);

        if (d1 > 9) d1 = 0;

        if (dv.charAt(0) != d1)
            result = false;

        d1 *= 2;
        for (i = 0; i < 12; i++) {
            d1 += c.charAt(11 - i) * (2 + ((i + 1) % 8));
        }

        d1 = 11 - (d1 % 11);
        if (d1 > 9) d1 = 0;

        if (dv.charAt(1) != d1)
            result = false;

        return result;
    }
    return false;
}

function validate_cpf(val) {

    if (val.match(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/) != null) {
        var val1 = val.substring(0, 3);
        var val2 = val.substring(4, 7);
        var val3 = val.substring(8, 11);
        var val4 = val.substring(12, 14);

        var i;
        var number;
        var result = true;

        number = (val1 + val2 + val3 + val4);

        s = number;
        c = s.substr(0, 9);
        var dv = s.substr(9, 2);
        var d1 = 0;

        for (i = 0; i < 9; i++) {
            d1 += c.charAt(i) * (10 - i);
        }

        if (d1 == 0)
            result = false;

        d1 = 11 - (d1 % 11);
        if (d1 > 9) d1 = 0;

        if (dv.charAt(0) != d1)
            result = false;

        d1 *= 2;
        for (i = 0; i < 9; i++) {
            d1 += c.charAt(i) * (11 - i);
        }

        d1 = 11 - (d1 % 11);
        if (d1 > 9) d1 = 0;

        if (dv.charAt(1) != d1)
            result = false;

        return result;
    }

    return false;
}
